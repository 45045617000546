import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'

if (process.env.NODE_ENV === 'development') {
  // const BASE_URL = 'https://dry-pine-r2ugu.cloud.serverless.com'
  const BASE_URL = 'https://files.usacnation.com'
  console.info(`Setting API URL to: ${BASE_URL}`)
  axios.defaults.baseURL = BASE_URL
}

createApp(App).mount('#app')
