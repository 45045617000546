<template>
  <div>
    <Error v-if="error" :message="error" />

    <div class="bg-white px-4 py-4 rounded-none sm:rounded-xl w-screen shadow-md max-w-xl">
      <div class="space-y-4">
        <div v-if="loading">Loading...</div>
        <div v-else-if="!files.length">No files found</div>
        <div v-else-if="node">
          <div>
            <nav v-if="ancestors && ancestors.length" class="text-black font-bold my-4 flex" aria-label="Breadcrumb">
              <!-- <ol class="list-none p-0 inline-flex">
                <li v-for="ancestor in ancestors" :key="ancestor.id" class="flex items-center">
                  <a class="cursor-pointer" href.prevent="#" @click="openNode(ancestor)">{{ ancestor.dataObj.name }}</a>
                  <svg class="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path
                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                    />
                  </svg>
                </li>
                <li>
                  <a href="#" class="text-gray-500" aria-current="page">{{ node.dataObj.name }}</a>
                </li>
              </ol> -->

              <a
                class="grow cursor-pointer inline-flex items-center"
                href.prevent="#"
                @click="openNode(ancestors[ancestors.length - 1])"
                ><svg class="w-5 mr-2" viewBox="-78.5 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <title>left</title>
                  <path d="M257 64L291 98 128 262 291 426 257 460 61 262 257 64Z" /></svg
                ><span>{{ node.dataObj.name }}</span></a
              ><svg
                class="w-5 mr-2 cursor-pointer"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
                @click="onRefresh"
              >
                <path
                  d="M 7.1601562 3 L 8.7617188 5 L 18 5 C 18.551 5 19 5.448 19 6 L 19 15 L 16 15 L 20 20 L 24 15 L 21 15 L 21 6 C 21 4.346 19.654 3 18 3 L 7.1601562 3 z M 4 4 L 0 9 L 3 9 L 3 18 C 3 19.654 4.346 21 6 21 L 16.839844 21 L 15.238281 19 L 6 19 C 5.449 19 5 18.552 5 18 L 5 9 L 8 9 L 4 4 z"
                />
              </svg>
            </nav>
          </div>
          <div v-if="!sortedChildren || !sortedChildren.length"><p class="text-center">No documents loaded yet</p></div>
          <ul v-else v-for="child in sortedChildren" :key="child.id">
            <li>
              <button
                @click.prevent="openNode(child)"
                :key="child.id"
                class="w-full inline-flex my-1 px-2 py-4 leading-6 text-sm shadow rounded-md text-black bg-gray-200 hover:bg-gray-400 transition ease-in-out duration-150"
              >
                <a target="_blank" :href="child.dataObj.url || '#'" class="inline-flex items-center"
                  ><span class="mr-2"><img v-if="child.dataObj.iconLink" :src="child.dataObj.iconLink" /></span>
                  <span class="text-ellipsis text-left">{{ child.dataObj.name }}</span></a
                >
              </button>
            </li>
          </ul>
        </div>

        <!-- <button
          class="w-full inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-red-500 hover:bg-red-400 transition ease-in-out duration-150"
          @click="clearCache"
          :disabled="loading"
        >
          <svg
            v-if="loading"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {{ loading ? `Reloading` : `Reload cache (testing)` }}
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
// TODO LIST
// - Drop unneeded npm packages
// - Look at minimizing tailwind CSS, removing JS
// - Wire up webhook and hide the "reload cache" button
// - Allow subscription to changes
// - Awesome if this could be branded or pinned to a folder as the root
// X Show "No documents loaded yet"
// X Setup a "view" or "download" link
// X Give a PDF version if it isn't a PDF? - https://support.google.com/a/users/answer/9308985?hl=en
// X Sort folders, then file names alphabetically
// X Hide file extensions
// X Any way to override the file name easily?
// X Related, can we make a file a pointer to another URL? (metadata)
// X Allow direct linking to a folder

import Error from './Error.vue'
import { orderBy } from 'natural-orderby'
const tree_util = require('tree-util')

export default {
  props: {
    axios: {
      type: Function,
      required: true,
    },
  },
  components: { Error },
  created() {
    this.loadFiles()
  },
  data() {
    return {
      loading: false,
      error: '',
      nodeId: null,
      files: [],
      tree: null,
    }
  },
  computed: {
    sortedChildren() {
      return this.node?.children
        ? orderBy(this.node.children, [(v) => !v.dataObj.isFolder, (v) => v.dataObj.name], ['asc', 'asc'])
        : []
    },
    ancestors() {
      return this.node ? this.getAncestors(this.node) : null
    },
    node() {
      return this.tree && this.nodeId ? this.tree.getNodeById(this.nodeId) : null
    },
  },
  methods: {
    getAncestors(node) {
      return node.getAncestors().reverse()
    },
    async clearCache() {
      await this.axios.post('/api/webhooks')
      await this.loadFiles()
    },
    openNode(node) {
      if (node.dataObj.mimeType === 'application/vnd.google-apps.folder') {
        this.nodeId = node.id
        this.updateUrl(node)
      } else {
        window.open(node.dataObj.url, '_blank')
      }
    },
    onRefresh() {
      location.reload()
    },
    updateUrl(node) {
      // Update URL
      const hierarchy = this.getAncestors(node)
      hierarchy.push(node)
      let nextURL = []
      for (let i = 1; i < hierarchy.length; i++) {
        const currentNode = hierarchy[i]
        nextURL.push(currentNode.dataObj.name)
      }
      window?.history?.pushState({}, nextURL.join(' > '), '/' + nextURL.join('/'))
    },
    getDefaultNode() {
      const desiredNodes =
        window?.location?.pathname
          ?.split('/')
          ?.filter((e) => !!e)
          .map((e) => decodeURIComponent(e)) || null

      // Default to root level
      if (!Array.isArray(desiredNodes) || !desiredNodes.length) return this.tree.rootNode

      let currentNode = this.tree.rootNode
      for (let i = 0; i < desiredNodes.length; i++) {
        const desiredName = desiredNodes[i]

        console.log(desiredName)

        // Find node in path
        const foundNode = currentNode.children.find((child) => child.dataObj.name === desiredName)

        // Bail out early if we don't find something in the path
        if (!foundNode) return currentNode

        // Keep walking down the tree structure
        currentNode = foundNode
      }
      return currentNode
    },
    async loadFiles() {
      try {
        this.loading = true
        this.error = ''

        const { data } = await this.axios.get('/api/files')
        this.files = Object.freeze(data)

        const ids = data.map(d => d.id)

        const filteredData = data.filter(d => {
          const isChildOrRoot = d.parentId === null || ids.includes(d.parentId)
          if (!isChildOrRoot) console.log(`No parent, removing: ${d.originalName}`)
          return isChildOrRoot
        })

        this.tree = Object.freeze(tree_util.buildTrees(filteredData, { id: 'id', parentid: 'parentId' })[0])

        const defaultNode = this.getDefaultNode()
        this.openNode(defaultNode)
      } catch (error) {
        console.log(error)
        this.error = error.toString()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
