<template>
  <div class="h-screen bg-gradient-to-br from-gray-600 to-gray-800 flex flex-col items-center w-full">
    <FileListing :axios="axiosInstance" />
  </div>
</template>

<script>
import axios from 'axios'
import FileListing from './components/FileListing.vue'

export default {
  components: {
    FileListing,
  },
  data() {
    return {
      axiosInstance: axios,
    }
  },
  methods: {},
}
</script>

<style></style>
